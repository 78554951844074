<template>
  <div>
  <floating-menu :event-id="eventId"></floating-menu>
  <b-row>
    <b-col cols="12">
      <event-scan-table />
    </b-col>
  </b-row>
</div>
</template>

<script>
import { BRow, BCol } from 'bootstrap-vue'
import EventScanTable from './EventScanTable.vue'
import router from '@/router'
import FloatingMenu from './FloatingMenu.vue'

export default {
  components: {
    BRow,
    BCol,
    FloatingMenu,
    EventScanTable
  },
  data() {
    return {
      eventId: router.currentRoute.params.id,
    }
  },
}
</script>

<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>